export const ProjectActionTypes = {
    SET_PROJECT: 'project/SET_PROJECT',
    SET_LOADING_PROJECT: 'project/SET_LOADING_PROJECT',
    SET_PROJECT_NOT_FOUND: 'project/SET_PROJECT_NOT_FOUND',

    SET_IS_VALID_ACCESS_CODE: 'project/SET_IS_VALID_ACCESS_CODE',

    SET_ADMIN_PROJECTS_DATA: 'admin/SET_ADMIN_PROJECTS_DATA',
    ADD_ADMIN_PROJECTS_DATA: 'admin/ADD_ADMIN_PROJECTS_DATA',
    SET_LOADING_ADMIN_PROJECTS: 'admin/SET_LOADING_ADMIN_PROJECTS',
    SET_LOADING_ADMIN_PROJECTS_FIRST_PAGE: 'admin/SET_LOADING_ADMIN_PROJECTS_FIRST_PAGE',
    DELETE_ADMIN_PROJECTS: 'admin/DELETE_ADMIN_PROJECTS',
    UPDATE_ADMIN_PROJECTS: 'admin/UPDATE_ADMIN_PROJECTS',

    SET_ACCESS_CODES_DATA: 'admin/SET_ACCESS_CODES_DATA',
    ADD_ACCESS_CODES_DATA: 'admin/ADD_ACCESS_CODES_DATA',
    SET_LOADING_ACCESS_CODES: 'admin/SET_LOADING_ACCESS_CODES',
    SET_LOADING_ACCESS_CODES_FIRST_PAGE: 'admin/SET_LOADING_ACCESS_CODES_FIRST_PAGE',
    DELETE_ACCESS_CODES: 'admin/DELETE_ACCESS_CODES',
    UPDATE_ACCESS_CODES: 'admin/UPDATE_ACCESS_CODES',

    SET_THIS_USER_PROJECTS_DATA: 'admin/SET_THIS_USER_PROJECTS_DATA',
    ADD_THIS_USER_PROJECTS_DATA: 'admin/ADD_THIS_USER_PROJECTS_DATA',
    SET_LOADING_THIS_USER_PROJECTS: 'admin/SET_LOADING_THIS_USER_PROJECTS',
    SET_LOADING_THIS_USER_PROJECTS_FIRST_PAGE: 'admin/SET_LOADING_THIS_USER_PROJECTS_FIRST_PAGE',
    DELETE_THIS_USER_PROJECTS: 'admin/DELETE_THIS_USER_PROJECTS',
    UPDATE_THIS_USER_PROJECTS: 'admin/UPDATE_THIS_USER_PROJECTS',

    SET_ACCESS_CODE: 'project/SET_ACCESS_CODE',
    SET_LOADING_ACCESS_CODE: 'project/SET_LOADING_ACCESS_CODE',
    SET_ACCESS_CODE_NOT_FOUND: 'project/SET_ACCESS_CODE_NOT_FOUND',
}