export const AdminActionTypes = {
    SET_ADMIN_USERS: 'admin/SET_ADMIN_USERS',
    SET_LOADING_ADMIN_USERS: 'admin/SET_LOADING_ADMIN_USERS',

    SET_USERS_SEARCH_RESULTS: 'admin/SET_USERS_SEARCH_RESULTS',
    CLEAR_USERS_SEARCH_RESUTLS: 'admin/CLEAR_USERS_SEARCH_RESULTS',
    SET_LOADING_USERS_SEARCH_RESULTS: 'admin/SET_LOADING_USERS_SEARCH_RESULTS',

    SET_BUG_REPORTS_DATA: 'admin/SET_BUG_REPORTS_DATA',
    ADD_BUG_REPORTS_DATA: 'admin/ADD_BUG_REPORTS_DATA',
    SET_LOADING_BUG_REPORTS: 'admin/SET_LOADING_BUG_REPORTS',
    SET_LOADING_BUG_REPORTS_FIRST_PAGE: 'admin/SET_LOADING_BUG_REPORTS_FIRST_PAGE',
    DELETE_BUG_REPORTS: 'admin/DELETE_BUG_REPORTS',
    UPDATE_BUG_REPORTS: 'admin/UPDATE_BUG_REPORTS',

    SET_BUG_REPORT: 'admin/SET_BUG_REPORT',
    SET_LOADING_BUG_REPORT: 'admin/SET_LOADING_BUG_REPORT',
    SET_BUG_REPORT_NOT_FOUND: 'admin/SET_BUG_REPORT_NOT_FOUND',

    SET_BUG_REPORT_STATS: 'admin/SET_BUG_REPORT_STATS',
    SET_LOADING_BUG_REPORT_STATS: 'admin/SET_LOADING_BUG_REPORT_STATS',

    SET_FAQS_DATA: 'admin/SET_FAQS_DATA',
    ADD_FAQS_DATA: 'admin/ADD_FAQS_DATA',
    SET_LOADING_FAQS: 'admin/SET_LOADING_FAQS',
    SET_LOADING_FAQS_FIRST_PAGE: 'admin/SET_LOADING_FAQS_FIRST_PAGE',
    UPDATE_FAQS: 'admin/UPDATE_FAQS',
    DELETE_FAQS: 'admin/DELETE_FAQS',

    SET_FAQ: 'admin/SET_FAQ',
    SET_LOADING_FAQ: 'admin/SET_LOADING_FAQ',
    SET_FAQ_NOT_FOUND: 'admin/SET_FAQ_NOT_FOUND',
}