export const CommunicationActionTypes = {
    DELETE_MESSAGE: 'communication/DELETE_MESSAGE',
    SET_MESSAGES: 'communication/SET_MESSAGES',
    
    SET_NOTIFICATIONS_DATA: 'communication/SET_NOTIFICATIONS_DATA',
    ADD_NOTIFICATIONS_DATA: 'communication/ADD_NOTIFICATIONS_DATA',
    MARK_NOTIFICATION_AS_READ: 'communication/MARK_NOTIFICATION_AS_READ',
    SET_LOADING_NOTIFICATIONS: 'communication/SET_LOADING_NOTIFICATIONS',
    SET_LOADING_NOTIFICATIONS_FIRST_PAGE: 'communication/SET_LOADING_NOTIFICATIONS_FIRST_PAGE',

    SET_CHANNEL_NOTIFICATIONS_DATA: 'communication/SET_CHANNEL_NOTIFICATIONS_DATA',
    ADD_CHANNEL_NOTIFICATIONS_DATA: 'communication/ADD_CHANNEL_NOTIFICATIONS_DATA',
    SET_LOADING_CHANNEL_NOTIFICATIONS: 'communication/SET_LOADING_CHANNEL_NOTIFICATIONS',
    SET_LOADING_CHANNEL_NOTIFICATIONS_FIRST_PAGE: 'communication/SET_LOADING_CHANNEL_NOTIFICATIONS_FIRST_PAGE',
}